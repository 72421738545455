<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col>
        <service-table></service-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const ServiceTable = () => import("../tables/ServiceTable.vue");

export default {
  components: {
    ServiceTable
  }
};
</script>
